import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GiftIcon, HomeIcon, CalendarIcon, UserGroupIcon, SparklesIcon, CameraIcon, CheckIcon } from '@heroicons/react/20/solid';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import Navbar from '../components/PublicNavbar';
import PublicFooter from '../components/PublicFooter';
import { FaWhatsapp } from 'react-icons/fa';

const features = [
  {
    name: 'O Vizită de Poveste',
    description: 'Moșul va asculta dorințele celor mici, le va citi din scrisori și le va oferi daruri direct din sacul său magic.',
    icon: GiftIcon,
  },
  {
    name: 'În Confortul Casei Tale',
    description: 'Moș Crăciun vine direct la voi acasă, aducând cu el farmecul sărbătorilor în cel mai personal și confortabil mod.',
    icon: HomeIcon,
  },
  {
    name: 'Programare Flexibilă',
    description: 'Alege data și ora care ți se potrivesc cel mai bine pentru a primi vizita Moșului și a crea amintiri de neuitat.',
    icon: CalendarIcon,
  },
  {
    name: 'Pentru Întreaga Familie',
    description: 'Bucuria și uimirea din ochii copiilor atunci când își văd eroul în carne și oase vor umple casa de veselie și râsete.',
    icon: UserGroupIcon,
  },
  {
    name: 'Magie Autentică',
    description: 'Cu barba lui naturală și prezența caldă, Moșul nostru aduce spiritul autentic al Crăciunului în casa ta.',
    icon: SparklesIcon,
  },
  {
    name: 'Amintiri de Neuitat',
    description: 'Capturează momentele magice cu Moș Crăciun pentru a păstra amintirea acestei experiențe unice pentru totdeauna.',
    icon: CameraIcon,
  },
];

const tiers = [
  {
    name: '30 minute',
    id: 'tier-30',
    href: '#',
    priceMonthly: '350 RON',
    description: 'O vizită scurtă, dar magică cu Moș Crăciun.',
    features: [
      'Moș Crăciun cu barbă naturală',
      'Interacțiune autentică cu copiii',
      'Flexibilitate în programare',
      'Recenzii excelente de la clienți',
    ],
    mostPopular: false,
  },
  {
    name: '45 minute',
    id: 'tier-45',
    href: '#',
    priceMonthly: '450 RON',
    description: 'Mai mult timp pentru povești și magie.',
    features: [
      'Moș Crăciun cu barbă naturală',
      'Interacțiune autentică cu copiii',
      'Flexibilitate în programare',
      'Recenzii excelente de la clienți',
    ],
    mostPopular: true,
  },
  {
    name: '60 minute',
    id: 'tier-60',
    href: '#',
    priceMonthly: '500 RON',
    description: 'Experiența completă pentru o seară de neuitat.',
    features: [
      'Moș Crăciun cu barbă naturală',
      'Interacțiune autentică cu copiii',
      'Flexibilitate în programare',
      'Recenzii excelente de la clienți',
    ],
    mostPopular: false,
  },
];

function classNames(...classes: (string | undefined | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

const MosCraciunCampanii: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleReserveClick = () => {
    setOpen(true);
  };

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/40748161436`, '_blank');
  };

  return (
    <HelmetProvider>
      <div className="bg-white min-h-screen">
        <Helmet>
          <title>Moș Crăciun Acasă - Experiența autentică de Crăciun</title>
          <meta
            name="description"
            content="Bucură-te de vizita unui Moș Crăciun autentic chiar la tine acasă. Creează amintiri de neuitat pentru toată familia!"
          />
        </Helmet>

        <Navbar />

        <main>
          <div className="bg-white pt-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:text-center">
                <h2 className="text-base font-semibold leading-7 text-red-600">Servicii Magice de Sărbători</h2>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Moș Crăciun cu Barbă Naturală</h1>
                <p className="mt-6 text-xl text-gray-600">
                  Adu magia sărbătorilor acasă cu un Moș Crăciun autentic, care va transforma o seară obișnuită de Crăciun într-o amintire de neuitat pentru întreaga familie!
                </p>
              </div>
            </div>

            <div className="relative overflow-hidden pt-10">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <img
                  src="/moscraciuncompanii.webp"
                  alt="Moș Crăciun cu barbă naturală"
                  className="rounded-xl"
                  style={{ height: '400px', objectFit: 'cover', objectPosition: 'top', }}
                  width={2432}
                  height={1442}
                />
              </div>
            </div>

            <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
              <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-red-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="bg-white py-24">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                  <h2 className="text-base font-semibold leading-7 text-red-600">Tarife</h2>
                  <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Alege pachetul perfect pentru tine
                  </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                  Indiferent de pachetul ales, vei beneficia de un Moș Crăciun cu barbă naturală și o experiență autentică și personalizată.
                </p>
                <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                  {tiers.map((tier, tierIdx) => (
                    <div
                      key={tier.id}
                      className={classNames(
                        tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                        tierIdx === 0 ? 'lg:rounded-r-none' : '',
                        tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                        'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10',
                      )}
                    >
                      <div>
                        <div className="flex items-center justify-between gap-x-4">
                          <h3
                            id={tier.id}
                            className={classNames(
                              tier.mostPopular ? 'text-red-600' : 'text-gray-900',
                              'text-lg font-semibold leading-8',
                            )}
                          >
                            {tier.name}
                          </h3>
                          {tier.mostPopular ? (
                            <p className="rounded-full bg-red-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-red-600">
                              Cel mai popular
                            </p>
                          ) : null}
                        </div>
                        <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                        <p className="mt-6 flex items-baseline gap-x-1">
                          <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.priceMonthly}</span>
                        </p>
                        <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                          {tier.features.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                              <CheckIcon className="h-6 w-5 flex-none text-red-600" aria-hidden="true" />
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button
                        onClick={handleReserveClick}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-red-600 text-white shadow-sm hover:bg-red-500'
                            : 'text-red-600 ring-1 ring-inset ring-red-200 hover:ring-red-300',
                          'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600',
                        )}
                      >
                        Rezervă acum
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Moșii noștri autentici</h2>
                  <p className="mt-6 text-xl leading-8 text-gray-600">
                    Descoperă echipa noastră de Moși Crăciun autentici, gata să aducă magia sărbătorilor în casa ta. Cu barbi naturale și costume tradiționale, ei sunt pregătiți să creeze amintiri de neuitat pentru întreaga familie.
                  </p>
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    Fiecare Moș din echipa noastră este atent selecționat pentru pasiunea și dedicarea sa în a aduce bucurie copiilor. Experiența lor vastă și atenția la detalii fac ca fiecare vizită să fie unică și plină de magie.
                  </p>
                  <div className="mt-10 flex">
                    <a
                      href="#"
                      className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    >
                      Vezi galeria Moșilor <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
                <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                  <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                    <img
                      alt="Moș Crăciun cu copii"
                      src="/santa/photo1.jpg"
                      className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover object-top"
                    />
                  </div>
                  <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                    <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                      <img
                        alt="Moș Crăciun lângă brad"
                        src="/santa/photo2.jpeg"
                        className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-top"
                      />
                    </div>
                    <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                      <img
                        alt="Moș Crăciun cu sac de cadouri"
                        src="/santa/photo3.jpg"
                        className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-top"
                      />
                    </div>
                    <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                      <img
                        alt="Moș Crăciun citind scrisori"
                        src="/santa/photo5.jpeg"
                        className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover object-top"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <PublicFooter />

        <Dialog open={open} onClose={() => setOpen(false)} className="relative z-50">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div>
                  <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-red-100">
                    <img src="/santa-contact.png" alt="Santa Contact" className="h-20 w-20" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                      Alege modalitatea de contact
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Pentru o rezervare rapidă și ușoară, te încurajăm să ne contactezi prin WhatsApp. Dacă preferi email-ul, te rugăm să ne scrii și vom reveni cu un răspuns cât mai curând posibil.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    onClick={handleWhatsAppClick}
                    className="inline-flex w-full justify-center items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                  >
                    <FaWhatsapp className="mr-2 h-5 w-5" />
                    WhatsApp
                  </button>
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  >
                    Email
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </div>
    </HelmetProvider>
  );
};

export default MosCraciunCampanii;