import React, { useState, useEffect } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface ChristmasMessageDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const ChristmasMessageDialog: React.FC<ChristmasMessageDialogProps> = ({ 
  isOpen: externalIsOpen, 
  onClose 
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isOpen = externalIsOpen !== undefined ? externalIsOpen : internalIsOpen;
  const handleClose = onClose || (() => setInternalIsOpen(false));

  useEffect(() => {
    if (externalIsOpen === undefined) {
      const hasSeenMessage = localStorage.getItem('hasSeenChristmasMessage2024');
      if (!hasSeenMessage) {
        const timer = setTimeout(() => setInternalIsOpen(true), 2000);
        return () => clearTimeout(timer);
      }
    }
  }, [externalIsOpen]);

  const handleDialogClose = () => {
    handleClose();
    localStorage.setItem('hasSeenChristmasMessage2024', 'true');
  };

  return (
    <Dialog 
      open={isOpen} 
      onClose={handleDialogClose}
      className="relative z-50"
    >
      <DialogBackdrop
        className="fixed inset-0 bg-black/70 backdrop-blur-sm transition-opacity"
      />
      
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
          >
            {/* Container pentru imagine și titlu - responsiv */}
            <div className="relative h-40 sm:h-80 bg-red-100">
              <img 
                src="/santadialog.webp" 
                alt="Santa" 
                className="w-full h-full object-cover object-center"
              />
              {/* Overlay gradient pentru titlu */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
              {/* Titlu suprapus peste imagine */}
              <Dialog.Title 
                as="h3" 
                className="absolute bottom-4 left-0 right-0 text-xl sm:text-2xl font-bold leading-6 text-white text-center px-4"
              >
                🎄 Este 24 decembrie – ziua în care magia prinde viață! 🎄
              </Dialog.Title>
            </div>

            {/* Buton de închidere */}
            <div className="absolute right-4 top-4 z-10">
              <button
                type="button"
                className="rounded-md bg-white/80 p-1 text-gray-400 hover:text-gray-500"
                onClick={handleDialogClose}
              >
                <span className="sr-only">Închide</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div className="px-4 py-3 sm:px-6 sm:py-6">
              {/* Conținut - responsiv */}
              <div className="space-y-2 sm:space-y-4 text-gray-700 leading-snug sm:leading-relaxed text-[0.875rem] sm:text-[0.925rem]">
                <p className="font-medium">
                  Dragii noștri,
                </p>
                <p>
                  Astăzi, în <span className="font-bold">Ajunul Crăciunului</span>, vrem să vă mulțumim din toată inima pentru că ați ales să fiți <span className="font-bold">parte din povestea noastră</span>. Pentru cei care ne-au fost alături an de an și pentru cei care ni s-au alăturat de curând, <span className="font-bold text-red-600">voi sunteți adevărata noastră inspirație</span>. ❤️
                </p>
                <p className="font-bold text-center sm:px-4 sm:py-2 sm:bg-red-50 sm:rounded-lg">
                  ✨ Promitem că și anul viitor vom păstra tradiția vie, iar Moș Crăciun va aduce bucurie și lumină în casele fiecărui copil! ✨
                </p>
                <p>
                  Astăzi, în această <span className="font-bold">zi specială</span>, vă dorim ca <span className="font-bold">magia Crăciunului</span> să vă umple inimile de iubire și recunoștință, așa cum voi ne umpleți pe noi de motivație și bucurie. 🌟
                </p>
                <p className="font-bold text-center mt-2 sm:mt-6">
                  Cu toată dragostea,<br />
                  <span className="text-red-600">Echipa noastră și Moș Crăciun!</span> 🎅🎁
                </p>
              </div>

              {/* Buton de închidere în partea de jos - responsiv */}
              <div className="mt-3 sm:mt-6 flex justify-center">
                <button
                  type="button"
                  className="rounded-full bg-red-600 px-6 sm:px-8 py-1.5 sm:py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-colors"
                  onClick={handleDialogClose}
                >
                  Mulțumim! ❤️
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>

      {/* Efect de ninsoare în fundal */}
      <div className="fixed inset-0 pointer-events-none">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute animate-snowfall"
            style={{
              left: `${Math.random() * 100}%`,
              top: `-20px`,
              width: `${Math.random() * 8 + 4}px`,
              height: `${Math.random() * 8 + 4}px`,
              background: 'white',
              borderRadius: '50%',
              opacity: Math.random() * 0.6 + 0.2,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${Math.random() * 3 + 5}s`
            }}
          />
        ))}
      </div>
    </Dialog>
  );
};

export default ChristmasMessageDialog;