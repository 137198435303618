import axios from 'axios';
import { Customer } from './customerService';
import { Character } from './characterService';

export const API_URL = process.env.REACT_APP_API_URL || 'https://localhost:5000';

export interface ExtraCharacter {
  character: Character;
  modelFee: number;
}

export interface Order {
  _id: string;
  shortId: string;
  character: Character;
  extraCharacters: ExtraCharacter[];
  period: {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
  };
  client: Partial<Customer>; 
  billingInfo: {
    totalAmount: number;
    modelFee: number;
    driverFee: number;
    billingType: 'individual' | 'company';
    individualBilling?: {
      county?: string;
      city?: string;
      street?: string;
      clientName?: string;
      cnp?: string;
      institution?: string;
    };
    companyBilling?: {
      companyName?: string;
      isVatPayer?: boolean;
      fiscalCode?: string;
      regCom?: string;
      county?: string;
      city?: string;
      address?: string;
      bank?: string;
      iban?: string;
      legalRep?: string;
      legalRepFunction?: string;
      institution?: string;
    };
    extraFees?: { [key: number]: number };
  };
  driver: {
    _id: string;
    firstName: string;
    lastName: string;
    phone?: string;
  } | null; 
  documents?: string[];
  status: 'active' | 'completed' | 'cancelled';
  addressDetails?: string;
  address: string;
  zone: string;
  notes?: string;
  createdAt?: Date;
  updatedAt?: Date;
  breakAfter: number; 
}

const getAuthHeaders = () => {
  const token = localStorage.getItem('accessToken');
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const getOrders = async (): Promise<Order[]> => {
  try {
    const response = await axios.get<Order[]>(`${API_URL}/api/orders`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea comenzilor', error);
    throw error;
  }
};

export const addOrder = async (formData: FormData): Promise<Order> => {
  try {
    const response = await axios.post<Order>(`${API_URL}/api/orders`, formData, {
      headers: {
        ...getAuthHeaders(),
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la adăugarea comenzii', error);
    if (axios.isAxiosError(error)) {
      console.error('Detalii eroare:', error.response?.data);
    }
    throw error;
  }
};

export const getOrderById = async (id: string): Promise<Order> => {
  try {
    const response = await axios.get<Order>(`${API_URL}/api/orders/${id}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea comenzii', error);
    throw error;
  }
};

export const updateOrder = async (id: string, orderData: Partial<Order>): Promise<Order> => {
  try {
    const formData = new FormData();

    Object.entries(orderData).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        if (key === 'driver') {
          // Dacă driver este null, trimitem explicit null (backend-ul trebuie să-l accepte)
          formData.append(key, value === null ? '' : (typeof value === 'string' ? value : (value as any)._id));
        } else if (key === 'character') {
          formData.append(key, typeof value === 'string' ? value : (value as any)._id);
        } else if (key === 'extraCharacters') {
          const extraCharacters = value as ExtraCharacter[];
          formData.append(key, JSON.stringify(extraCharacters.map(ec => ({
            character: typeof ec.character === 'string' ? ec.character : ec.character._id,
            modelFee: ec.modelFee,
          }))));
        } else if (key === 'client') {
          formData.append(key, JSON.stringify(value));
        } else if (typeof value === 'object') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value.toString());
        }
      } else if (key === 'driver') {
        // Ștergem driver-ul în caz de null
        formData.append(key, '');
      }
    });

    const response = await axios.put<Order>(`${API_URL}/api/orders/${id}`, formData, {
      headers: {
        ...getAuthHeaders(),
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Eroare la actualizarea comenzii', error);
    throw error;
  }
};

export const deleteOrder = async (id: string): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/api/orders/${id}`, {
      headers: getAuthHeaders(),
    });
  } catch (error) {
    console.error('Eroare la ștergerea comenzii', error);
    throw error;
  }
};

export const updateOrderStatus = async (id: string): Promise<Order> => {
  try {
    const response = await axios.put<Order>(`${API_URL}/api/orders/${id}/status`, {}, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la actualizarea statusului comenzii', error);
    throw error;
  }
};

export const addExtraCharacter = async (orderId: string, character: Character, modelFee: number): Promise<Order> => {
  try {
    const response = await axios.post<Order>(`${API_URL}/api/orders/${orderId}/extra-character`, 
      { character, modelFee },
      { headers: getAuthHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error('Eroare la adăugarea caracterului suplimentar', error);
    throw error;
  }
};

export const removeExtraCharacter = async (orderId: string, characterIndex: number): Promise<Order> => {
  try {
    const response = await axios.delete<Order>(`${API_URL}/api/orders/${orderId}/extra-character/${characterIndex}`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la eliminarea caracterului suplimentar', error);
    throw error;
  }
};

export const deleteOrderDocument = async (orderId: string, documentIndex: number): Promise<Order> => {
  try {
    const response = await axios.delete<Order>(`${API_URL}/api/orders/${orderId}/document`, {
      headers: getAuthHeaders(),
      data: { documentIndex },
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la ștergerea documentului comenzii', error);
    throw error;
  }
};

export const getGooglePlaceSuggestions = async (input: string): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/api/maps/autocomplete`, {
      params: { input }
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea sugestiilor de locații:', error);
    throw error;
  }
};

export const getExistingOrders = async (characterId: string, date: string): Promise<Order[]> => {
  try {
    const response = await axios.get<Order[]>(`${API_URL}/api/orders/existing-orders`, {
      params: { characterId, date },
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea comenzilor existente', error);
    throw error;
  }
};

export const calculateDistance = async (origin: string, destination: string): Promise<{ distance: string; duration: string }> => {
  try {
    const response = await axios.get<{ distance: string; duration: string }>(`${API_URL}/api/orders/calculate-distance`, {
      params: { origin, destination },
      headers: getAuthHeaders(),
    });
    
    // Convertim durata în română
    const durationInRomanian = response.data.duration
      .replace('hours', 'ore')
      .replace('hour', 'oră')
      .replace('minutes', 'minute')
      .replace('minute', 'minut');
    
    return {
      distance: response.data.distance,
      duration: durationInRomanian
    };
  } catch (error) {
    console.error('Eroare la calculul distanței:', error);
    throw error;
  }
};

export const checkAvailability = async (characterId: string, date: string, startTime: string, endTime: string): Promise<{ available: boolean; conflictingOrders?: Order[] }> => {
  try {
    const response = await axios.get<{ available: boolean; conflictingOrders?: Order[] }>(`${API_URL}/api/orders/check-availability`, {
      params: { characterId, date, startTime, endTime },
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la verificarea disponibilității', error);
    throw error;
  }
};

export const canScheduleOrder = (
  lastOrderEndTime: string,
  lastOrderEndDate: Date,
  newStartTime: string,
  breakAfter: number
): boolean => {
  const isValidTimeFormat = (time: string): boolean => {
    return /^([01]\d|2[0-3]):([0-5]\d)$/.test(time);
  };

  if (!isValidTimeFormat(lastOrderEndTime) || !isValidTimeFormat(newStartTime)) {
    console.error('Format de timp invalid');
    return false;
  }

  const [lastEndHour, lastEndMinute] = lastOrderEndTime.split(':').map(Number);
  const lastOrderEndDateTime = new Date(lastOrderEndDate);
  lastOrderEndDateTime.setHours(lastEndHour, lastEndMinute, 0, 0);

  const lastOrderEndDateTimePlusBreak = new Date(lastOrderEndDateTime.getTime() + breakAfter * 60000);

  const [newStartHour, newStartMinute] = newStartTime.split(':').map(Number);
  const newOrderStartDateTime = new Date(lastOrderEndDate);
  newOrderStartDateTime.setHours(newStartHour, newStartMinute, 0, 0);

  console.log('Ultima comandă se termină la:', lastOrderEndDateTime);
  console.log('Ultima comandă + pauză se termină la:', lastOrderEndDateTimePlusBreak);
  console.log('Noul timp de start:', newOrderStartDateTime);

  // Permitem programarea dacă noua comandă începe la sau după sfârșitul pauzei
  const canSchedule = newOrderStartDateTime.getTime() >= lastOrderEndDateTimePlusBreak.getTime();

  console.log('Se poate programa noua comandă?', canSchedule);

  return canSchedule;
};

export const getOrderStats = async (): Promise<{
  totalOrders: number;
  totalAmount: number;
  totalModelFees: number;
  totalDriverFees: number;
}> => {
  try {
    const response = await axios.get(`${API_URL}/api/orders/stats`, {
      headers: getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    console.error('Eroare la obținerea statisticilor comenzilor', error);
    throw error;
  }
};

export const cancelOrder = async (orderId: string, cancellationReason: string) => {
  try {
    const response = await axios.put(
      `${API_URL}/api/orders/${orderId}/status`,
      { status: 'cancelled', cancellationReason },
      { headers: getAuthHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error('Eroare la anularea comenzii', error);
    throw error;
  }
};
