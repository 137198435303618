import React, { useState, useEffect, useRef } from 'react';
import '../styles/cards.css';
import CharacterCarousel from './CharacterCarousel';
import { getPublicCharacters, PublicCharacter } from '../../services/publicCharacterService';

interface CharacterCardProps {
  character: PublicCharacter;
  status: string;
  onClick: () => void;
}

const CharacterCard: React.FC<CharacterCardProps> = ({ character, status, onClick }) => {
  const getBackgroundImage = (imagePath: string) => {
    if (!imagePath) return 'none';
    const encodedPath = encodeURI(imagePath);
    return `url("${encodedPath}")`;
  };

  return (
    <div
      className="w-64 h-80 flex-shrink-0 overflow-hidden relative cursor-pointer character-card"
      onClick={onClick}
    >
      <div
        className="w-full h-full rounded-dribbble bg-cover bg-top"
        style={{
          backgroundImage: getBackgroundImage(character.mainImage),
        }}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60 rounded-dribbble"></div>
      <div className="absolute bottom-0 left-0 right-0 p-4">
        <h3 className="text-white font-semibold text-lg line-clamp-2">{character.name}</h3>
        <p className="text-white text-sm mt-1">{status}</p>
      </div>
      {!character.mainImage && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 rounded-dribbble">
          <span className="text-gray-500 text-sm">No Image</span>
        </div>
      )}
    </div>
  );
};

const CharacterCards: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState<number | null>(null);
  const [characters, setCharacters] = useState<PublicCharacter[]>([]);
  const [isMarqueeEnabled, setIsMarqueeEnabled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const statuses = [
    '🎅 Aduc zâmbete și povești direct la tine acasă',
    '🎄 Bucuria Crăciunului începe cu o ușă deschisă și un Moș pregătit',
    '✨ Magie, emoție și râsete pentru cei mai cuminți copii',
    '🎁 În sacul meu sunt doar cadouri și amintiri frumoase',
    '🌟 Visez la ochii mari și plini de lumină ai copiilor',
    '❄️ O vizită de neuitat, un Crăciun de poveste',
    '🎅 Moșul cu barbă naturală care aduce Crăciunul la viață',
    '🎄 Când Moșul bate la ușă, magia începe imediat',
    '✨ Am venit din Laponia special pentru voi, cu sacul plin',
    '🎁 Ho-ho-ho, e timpul să creăm amintiri pentru o viață',
    '🌟 Un Moș autentic, pentru familii care iubesc Crăciunul',
    '❄️ Bucuria copilului tău este cea mai frumoasă poveste',
    '🎅 Am barba albă, sacul plin și sufletul cald',
    '🎄 Emoția Crăciunului într-o singură seară magică',
    '✨ Nu aduc doar cadouri, ci și râsete molipsitoare',
    '🎁 O singură vizită, o copilărie plină de amintiri',
    '🌟 Sunt pregătit să ascult dorințele celor mici',
    '❄️ Crăciunul e mai frumos cu Moș Crăciun autentic la ușă',
    '🎅 Veselie, magie și povești spuse la gura sobei',
    '🎄 Adu Crăciunul în casa ta cu o vizită specială',
    '✨ Copilul tău merită un Moș adevărat, cu barbă naturală',
    '🎁 Nu doar o vizită, ci un moment care rămâne în suflet',
    '🌟 Un Crăciun fără Moșul la ușă e ca o poveste neterminată',
    '❄️ Emoții, râsete și amintiri care nu se uită niciodată',
    '🎅 Magia Crăciunului începe cu un simplu ho-ho-ho',
    '🎄 În sacul meu nu sunt doar cadouri, ci și bucurii',
    '✨ Barba e reală, zâmbetele sunt garantate',
    '🎁 Aștept să aud râsetele și uimirea celor mici',
    '🌟 Un Moș Crăciun cum ai visat, la tine în sufragerie',
    '❄️ Adu Crăciunul la viață, în cel mai autentic mod',
    '🎅 Râsete, emoții și cadouri pentru cei mai cuminți copii',
    '🎄 Voi bate la ușă cu un sac plin de bucurie și magie',
    '✨ De Crăciun, copilul tău merită cea mai frumoasă poveste',
    '🎁 Nu e doar un Moș, e un prieten al copilăriei',
    '🌟 Am venit de departe, să aduc magia Crăciunului la tine',
    '❄️ Cel mai frumos cadou? Bucuria din ochii celor mici',
    '🎅 Moșul cu barba albă, ho-ho-ho și povești la purtător',
    '🎄 Transform o seară obișnuită într-o poveste de Crăciun',
    '✨ Luminile bradului și râsetele copiilor sunt tot ce contează',
    '🎁 Ho-ho-ho, magia începe când deschizi ușa Moșului',
  ];

  const [characterStatuses, setCharacterStatuses] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const fetchedCharacters = await getPublicCharacters();
        setCharacters(fetchedCharacters);
      } catch (error) {
        console.error('Eroare la încărcarea personajelor:', error);
      }
    };

    fetchCharacters();
  }, []);

  useEffect(() => {
    const assignStatuses = () => {
      const availableStatuses = [...statuses];
      const newCharacterStatuses: { [key: string]: string } = {};

      characters.forEach((character) => {
        if (!characterStatuses[character._id]) {
          const randomIndex = Math.floor(Math.random() * availableStatuses.length);
          const status = availableStatuses[randomIndex];
          newCharacterStatuses[character._id] = status;
          availableStatuses.splice(randomIndex, 1);
        } else {
          newCharacterStatuses[character._id] = characterStatuses[character._id];
        }
      });

      setCharacterStatuses(newCharacterStatuses);
    };

    assignStatuses();
  }, [characters]);

  useEffect(() => {
    const checkMarquee = () => {
      if (containerRef.current) {
        const { scrollWidth, clientWidth } = containerRef.current;
        setIsMarqueeEnabled(scrollWidth > clientWidth);
      }
    };

    checkMarquee();
    window.addEventListener('resize', checkMarquee);
    return () => window.removeEventListener('resize', checkMarquee);
  }, [characters]);

  useEffect(() => {
    document.body.style.overflow = showOverlay ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [showOverlay]);

  const handleCardClick = (index: number) => {
    if (characters[index].mainImage) {
      setSelectedCharacterIndex(index);
      setShowOverlay(true);
    }
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setSelectedCharacterIndex(null);
  };

  return (
    <>
      <div className="w-full overflow-x-auto character-cards-container" ref={containerRef}>
        <div className={`inline-flex space-x-6 py-4 ${isMarqueeEnabled ? 'animate-marquee-horizontal' : ''}`}>
          {characters.map((character, index) => (
            <CharacterCard
              key={`${character._id}-${index}`}
              character={character}
              status={characterStatuses[character._id] || ''}
              onClick={() => handleCardClick(index)}
            />
          ))}
        </div>
      </div>
      {showOverlay && selectedCharacterIndex !== null && (
        <div className="fixed inset-0 z-50">
          <CharacterCarousel
            characters={characters}
            initialCharacterIndex={selectedCharacterIndex}
            onClose={handleCloseOverlay}
          />
        </div>
      )}
    </>
  );
};

export default CharacterCards;