import React from 'react';
import { Character, DetailedOrder } from '../../services/characterService';
import { formatInTimeZone } from 'date-fns-tz';
import { ro } from 'date-fns/locale';

interface PrintableEarningsProps {
  orders: DetailedOrder[];
  hideTotalAmount: boolean;
  generatedBy: Character;
  filterDates: {
    startDate: string;
    endDate: string;
  };
}

const PrintableEarnings: React.FC<PrintableEarningsProps> = ({
  orders,
  hideTotalAmount,
  generatedBy,
  filterDates
}) => {
  const calculateTotals = () => {
    return orders.reduce((acc, order) => ({
      orders: acc.orders + 1,
      totalAmount: acc.totalAmount + order.financials.orderTotal,
      modelEarnings: acc.modelEarnings + order.financials.characterEarnings,
      driverEarnings: acc.driverEarnings + (order.financials.driverEarnings || 0)
    }), {
      orders: 0,
      totalAmount: 0,
      modelEarnings: 0,
      driverEarnings: 0
    });
  };

  const totals = calculateTotals();

  const formatRomanianNumber = (value: number): string => {
    if (!value) return '0,00';
    const withDecimals = value.toFixed(2);
    const [integerPart, decimalPart] = withDecimals.split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return `${formattedInteger},${decimalPart}`;
  };

  return (
    <div className="hidden print:block w-full p-4">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <img 
          src="https://inchiriazamoscraciun.ro/logoinchiriazamoscraciun.png" 
          alt="Logo" 
          className="h-16"
        />
        <div className="text-right">
          <p>Document generat: {new Date().toLocaleString('ro-RO', { dateStyle: 'full', timeStyle: 'long', timeZone: 'Europe/Bucharest' })}</p>
          <p>Model: {generatedBy.firstName} {generatedBy.lastName}</p>
          <p>Perioada: {filterDates.startDate || 'Toate'} - {filterDates.endDate || 'Toate'}</p>
        </div>
      </div>

      {/* Summary Box */}
      <div className="mb-6 border p-4 bg-gray-50 rounded">
        <h2 className="text-lg font-bold mb-4">Sumar Total:</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div>
            <span className="font-semibold">Total Comenzi:</span>
            <br />{totals.orders}
          </div>
          {!hideTotalAmount && (
            <div>
              <span className="font-semibold">Total Venituri Comenzi:</span>
              <br />{formatRomanianNumber(totals.totalAmount)} RON
            </div>
          )}
          <div>
            <span className="font-semibold">Total Venituri Model:</span>
            <br />{formatRomanianNumber(totals.modelEarnings)} RON
          </div>
          <div>
            <span className="font-semibold">Total Venituri Șoferi:</span>
            <br />{formatRomanianNumber(totals.driverEarnings)} RON
          </div>
        </div>
      </div>

      {/* Orders Table */}
      <div className="mb-8">
        <table className="w-full border-collapse text-xs">
          <thead>
            <tr className="bg-red-50">
              <th className="border border-gray-400 p-2 text-left">Client</th>
              <th className="border border-gray-400 p-2 text-left">Data/Ora</th>
              <th className="border border-gray-400 p-2 text-left">Locație</th>
              <th className="border border-gray-400 p-2 text-left">Șofer</th>
              {!hideTotalAmount && (
                <th className="border border-gray-400 p-2 text-right">Total Comandă</th>
              )}
              <th className="border border-gray-400 p-2 text-right">Câștig Model</th>
              <th className="border border-gray-400 p-2 text-right">Câștig Șofer</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={order.orderId} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="border border-gray-300 p-2">
                  <div className="font-bold">{order.client.name || 'N/A'}</div>
                  <div>Tel: {order.client.phone || 'N/A'}</div>
                </td>
                <td className="border border-gray-300 p-2">
                  <div>
                    {formatInTimeZone(
                      order.period.startDate,
                      'Europe/Bucharest',
                      'dd MMMM yyyy',
                      { locale: ro }
                    )}
                  </div>
                  <div>{order.period.startTime} - {order.period.endTime}</div>
                </td>
                <td className="border border-gray-300 p-2">
                  <div>{order.location.address}</div>
                  {order.location.addressDetails && (
                    <div className="text-gray-600">Detalii: {order.location.addressDetails}</div>
                  )}
                  {order.location.zone && (
                    <div className="text-gray-600">Zona: {order.location.zone}</div>
                  )}
                </td>
                <td className="border border-gray-300 p-2">
                  {order.driver ? (
                    <>
                      <div>{order.driver.name}</div>
                      <div className="text-gray-600">{order.driver.phone}</div>
                    </>
                  ) : (
                    <div className="text-gray-500">Fără șofer</div>
                  )}
                </td>
                {!hideTotalAmount && (
                  <td className="border border-gray-300 p-2 text-right">
                    {formatRomanianNumber(order.financials.orderTotal)} RON
                  </td>
                )}
                <td className="border border-gray-300 p-2 text-right font-medium text-green-700">
                  {formatRomanianNumber(order.financials.characterEarnings)} RON
                </td>
                <td className="border border-gray-300 p-2 text-right font-medium text-blue-700">
                  {formatRomanianNumber(order.financials.driverEarnings || 0)} RON
                </td>
              </tr>
            ))}
            
            {/* Totals Row */}
            <tr className="bg-gray-200 font-bold">
              <td colSpan={3} className="border border-gray-300 p-2">
                TOTAL
              </td>
              <td className="border border-gray-300 p-2"></td>
              {!hideTotalAmount && (
                <td className="border border-gray-300 p-2 text-right">
                  {formatRomanianNumber(totals.totalAmount)} RON
                </td>
              )}
              <td className="border border-gray-300 p-2 text-right text-green-700">
                {formatRomanianNumber(totals.modelEarnings)} RON
              </td>
              <td className="border border-gray-300 p-2 text-right text-blue-700">
                {formatRomanianNumber(totals.driverEarnings)} RON
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Footer */}
      <div className="text-center text-xs text-gray-500 mt-8">
        <p>www.inchiriazamoscraciun.ro | contact@inchiriazamoscraciun.ro | +40 749 318 211</p>
        <p>Website-ul inchiriazamoscraciun.ro</p>
      </div>
    </div>
  );
};

export default PrintableEarnings;