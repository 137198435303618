import React, { useEffect, useMemo } from 'react';
import { Listbox } from '@headlessui/react';
import { MagnifyingGlassIcon, ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import CustomDatePicker from '../components/CustomDatePicker';
import { Character } from '../../services/characterService';

interface FiltersProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  selectedCharacter: Character | null;
  onCharacterChange: (character: Character | null) => void;
  characters: Character[];
  filterDates: {
    startDate: string;
    endDate: string;
  };
  onDateChange: (type: 'startDate' | 'endDate', date: string) => void;
}

const EarningsFilters: React.FC<FiltersProps> = ({
  searchTerm,
  onSearchChange,
  selectedCharacter,
  onCharacterChange,
  characters,
  filterDates,
  onDateChange
}) => {
  // Sortarea listei de caractere folosind useMemo pentru optimizare
  const sortedCharacters = useMemo(() => {
    return [...characters].sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase().trim();
      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase().trim();
      return nameA.localeCompare(nameB, 'ro', { sensitivity: 'base' });
    });
  }, [characters]);

  // Setează primul caracter dacă nu există unul selectat
  useEffect(() => {
    if (!selectedCharacter && sortedCharacters.length > 0) {
      onCharacterChange(sortedCharacters[0]);
    }
  }, [selectedCharacter, sortedCharacters, onCharacterChange]);

  return (
    <div className="mb-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* Câmp de Căutare */}
        <div>
          <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-2">
            Căutare
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              className="block w-full h-[38px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Caută după ID comandă sau dată..."
            />
          </div>
        </div>

        {/* Selector Model */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Model
          </label>
          <Listbox value={selectedCharacter} onChange={onCharacterChange}>
            <div className="relative">
              <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">
                  {selectedCharacter
                    ? `${selectedCharacter.firstName} ${selectedCharacter.lastName}`
                    : 'Selectează model'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {sortedCharacters.map((character) => (
                  <Listbox.Option
                    key={character._id}
                    value={character}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                      }`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {character.firstName} {character.lastName}
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>

        {/* Selectoare de Dată */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Data Început
          </label>
          <CustomDatePicker
            selectedDate={filterDates.startDate}
            onChange={(date) => onDateChange('startDate', date)}
            name="startDate"
            label=""
            placeholder="ZZ.LL.AAAA"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Data Sfârșit
          </label>
          <CustomDatePicker
            selectedDate={filterDates.endDate}
            onChange={(date) => onDateChange('endDate', date)}
            name="endDate"
            label=""
            placeholder="ZZ.LL.AAAA"
          />
        </div>
      </div>
    </div>
  );
};

export default EarningsFilters;
