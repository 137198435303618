import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GiftIcon, HomeIcon, CalendarIcon, UserGroupIcon, SparklesIcon, CameraIcon, CheckIcon, TruckIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import Navbar from '../components/PublicNavbar';
import PublicFooter from '../components/PublicFooter';
import { FaWhatsapp } from 'react-icons/fa';
import WhatsAppFloatingButton from '../components/WhatsAppFloatingButton';


const features = [
  {
    name: 'Imaginează-ți cât de încântată ',
    description: 'va fi familia ta atunci când, în mijlocul serii, Moș Crăciun cel adevărat își face apariția la ușa voastră, cu barba lui albă și strălucirea Crăciunului în ochi.',
    icon: GiftIcon,
  },
  {
    name: 'Fiecare copil visează la întâlnirea cu Moș Crăciun.',
    description: 'Iar cu ajutorul unui Moș cu barbă naturală, zâmbet larg și o prezență caldă, visul poate deveni realitate!',
    icon: HomeIcon,
  },
  {
    name: 'Moș Crăciun nu este doar pentru cei mici.',
    description: 'Prezența sa creează o atmosferă de basm și pentru adulți, readucând amintirile copilăriei și reînviind spiritul Crăciunului în inima fiecăruia.',
    icon: CalendarIcon,
  },
  {
    name: 'Fie că este o seară liniștită în familie',
    description: 'sau o reuniune cu rudele, vizita Moșului va face ca toată casa să prindă viață, răspândind bucurie și emoție.',
    icon: UserGroupIcon,
  },
  {
    name: 'Nu trebuie sa mergi într-un loc aglomerat',
    description: 'pentru a te bucura de Magia Craciunului. Moș Crăciun vine direct acasă si aduce farmecul sărbătorilor in cel mai personal mod.',
    icon: SparklesIcon,
  },
  {
    name: 'Transformă Crăciunul de acasă',
    description: 'într-o poveste adevărată cu ajutorul unui Moș Crăciun autentic, cu barbă naturală, care să aducă un strop de magie și un sac plin de zâmbete.',
    icon: CameraIcon,
  },
];

const tiers = [
  {
    name: '30 minute',
    id: 'tier-30',
    href: '#',
    priceMonthly: '350 RON',
    description: 'O vizită scurtă, dar magică cu Moș Crăciun.',
    features: [
      { text: 'Moș Crăciun cu barbă naturală', icon: CheckIcon },
      { text: 'Interacțiune autentică cu copiii', icon: CheckIcon },
      { text: 'Flexibilitate în programare', icon: CheckIcon },
      { text: 'Recenzii excelente de la clienți', icon: CheckIcon },
      { text: 'Pentru ILFOV se percepe o taxa de transport de 50 RON', icon: InformationCircleIcon },
    ],
    mostPopular: false,
  },
  {
    name: '60 minute',
    id: 'tier-60',
    href: '#',
    priceMonthly: '550 RON',
    description: 'Experiența completă pentru o seară de neuitat.',
    features: [
      { text: 'Moș Crăciun cu barbă naturală', icon: CheckIcon },
      { text: 'Interacțiune autentică cu copiii', icon: CheckIcon },
      { text: 'Flexibilitate în programare', icon: CheckIcon },
      { text: 'Recenzii excelente de la clienți', icon: CheckIcon },
      { text: 'Pentru ILFOV se percepe o taxa de transport de 50 RON', icon: InformationCircleIcon },
    ],
    mostPopular: false,
  },
];

function classNames(...classes: (string | undefined | boolean)[]) {
  return classes.filter(Boolean).join(' ');
}

const MosCraciunAcasa: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleReserveClick = () => {
    setOpen(true);
  };

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/40748161436`, '_blank');
  };

  return (
    <HelmetProvider>
      <div className="bg-white min-h-screen">
        <Helmet>
          <title>Moș Crăciun Acasă - Experiența autentică de Crăciun</title>
          <meta
            name="description"
            content="Bucură-te de vizita unui Moș Crăciun autentic chiar la tine acasă. Creează amintiri de neuitat pentru toată familia!"
          />
        </Helmet>

        <Navbar />

        <main>
          <div className="bg-white pt-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl sm:text-center">
                <h2 className="text-base font-semibold leading-7 text-red-600">Mos Craciun de inchiriat</h2>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">De sarbatori casa lui Mos Craciun este la tine acasa</h1>
                <p className="mt-6 text-xl text-gray-600">
                  Fa alegerea potrivita, inchiriaza un Mos Craciun cu barba naturala si transforma pentru o seara casa ta in casa lui Mos Craciun.
                </p>
              </div>
            </div>

            <div className="relative overflow-hidden pt-10">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <img
                  src="/moscraciunlatineacasa.jpg"
                  alt="Moș Crăciun cu barbă naturală"
                  className="rounded-xl"
                  style={{ height: '350px', objectFit: 'cover', objectPosition: 'top', }}
                  width={2432}
                  height={1442}
                />
              </div>
            </div>

            <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
              <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-red-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="bg-white py-24">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                  <h2 className="text-base font-semibold leading-7 text-red-600">Tarife</h2>
                  <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Alege pachetul perfect pentru familia ta
                  </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                  Indiferent de pachetul ales, vei beneficia de un Moș Crăciun cu barbă naturală și o experiență autentică și personalizată.
                </p>
                <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 gap-x-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 justify-center">
                  {tiers.map((tier, tierIdx) => (
                    <div
                      key={tier.id}
                      className="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10"
                    >
                      <div>
                        <div className="flex items-center justify-between gap-x-4">
                          <h3
                            id={tier.id}
                            className="text-lg font-semibold leading-8 text-gray-900"
                          >
                            {tier.name}
                          </h3>
                        </div>
                        <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                        <p className="mt-6 flex items-baseline gap-x-1">
                          <span className="text-4xl font-bold tracking-tight text-gray-900">{tier.priceMonthly}</span>
                        </p>
                        <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                          {tier.features.map((feature, featureIdx) => (
                            <li key={featureIdx} className="flex gap-x-3">
                              <feature.icon className="h-6 w-5 flex-none text-red-600" aria-hidden="true" />
                              {feature.text}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button
                        onClick={handleReserveClick}
                        aria-describedby={tier.id}
                        className="mt-8 block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      >
                        Rezervă acum
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Mos Craciun & Echipa lui</h2>
                  <p className="mt-6 text-xl leading-8 text-gray-600">
                    Ajutat de spiridușii săi, Moș Crăciun aduce cu el nu doar cadouri, ci și magia autentică a Crăciunului, păstrată cu grijă în fiecare detaliu al vizitei sale. 
                  </p>
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    Fiecare apariție devine un moment de poveste, o oportunitate de a răspândi bucuria sărbătorilor și de a oferi un strop din atmosfera de basm care ne leagă pe toți în această perioadă.
                  </p>
                  <div className="mt-10 flex">
                    <a
                      href="/portfolio"
                      className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    >
                      Vezi galeria Moșilor <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
                <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                  <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                    <img
                      alt="Moș Crăciun cu copii"
                      src="/santa/photo1.jpg"
                      className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover object-top"
                    />
                  </div>
                  <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                    <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                      <img
                        alt="Moș Crăciun lângă brad"
                        src="/santa/photo2.jpeg"
                        className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-top"
                      />
                    </div>
                    <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                      <img
                        alt="Moș Crăciun cu sac de cadouri"
                        src="/santa/photo3.jpg"
                        className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-top"
                      />
                    </div>
                    <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                      <img
                        alt="Moș Crăciun citind scrisori"
                        src="/santa/photo5.jpeg"
                        className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover object-top"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <PublicFooter />

        <WhatsAppFloatingButton />

        <Dialog open={open} onClose={() => setOpen(false)} className="relative z-50">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div>
                  <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-red-100">
                    <img src="/santa-contact.png" alt="Santa Contact" className="h-20 w-20" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                      Alege modalitatea de contact
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Pentru o rezervare rapidă și ușoară, te încurajăm să ne contactezi prin WhatsApp. Dacă preferi email-ul, te rugăm să ne scrii și vom reveni cu un răspuns cât mai curând posibil.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    onClick={handleWhatsAppClick}
                    className="inline-flex w-full justify-center items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                  >
                    <FaWhatsapp className="mr-2 h-5 w-5" />
                    WhatsApp
                  </button>
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  >
                    Email
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </div>
    </HelmetProvider>
  );
};

export default MosCraciunAcasa;