import React from 'react';
import { Star } from 'lucide-react';

const featuredTestimonial = {
  body: 'A fost o experiență magică pentru întreaga familie. Moș Crăciun a fost autentic, răbdător și a creat o atmosferă de basm. Copiii au fost încântați, iar noi, adulții, ne-am simțit din nou ca în copilărie.',
  author: {
    name: 'Ionela Radu',
    rating: 5,
  },
};

const testimonials = [
  [
    [
      {
        body: 'Moș Crăciun a fost exact cum ne-am imaginat - cu barbă naturală, costum elegant și acea căldură în glas care te face să crezi cu adevărat în magia sărbătorilor.',
        author: {
          name: 'Mariana Neagu',
          rating: 5,
        },
      },
      {
        body: 'Am apreciat foarte mult punctualitatea și profesionalismul. A știut exact cum să interacționeze cu copiii și să creeze momente memorabile.',
        author: {
          name: 'Gabriel Mihai',
          rating: 5,
        },
      },
    ],
    [
      {
        body: 'O surpriză minunată pentru copii. Prezența Moșului a adus atâta bucurie și emoție în casa noastră. Cu siguranță vom repeta experiența.',
        author: {
          name: 'Elena Voicu',
          rating: 5,
        },
      },
      {
        body: 'Recomand cu încredere! A fost o seară magică, iar Moșul a avut răbdare să asculte fiecare copil și să creeze momente speciale pentru fiecare.',
        author: {
          name: 'Cristian Petrescu',
          rating: 5,
        },
      },
    ],
  ],
  [
    [
      {
        body: 'Am organizat un eveniment corporativ și Moș Crăciun a fost perfect - profesionist, amuzant și a știut să se adapteze perfect atmosferei.',
        author: {
          name: 'Marius Dumitru',
          rating: 5,
        },
      },
      {
        body: 'O experiență autentică și caldă. Copiii au fost fascinați de prezența Moșului, iar momentele petrecute împreună au fost cu adevărat speciale.',
        author: {
          name: 'Adina Popa',
          rating: 5,
        },
      },
    ],
    [
      {
        body: 'Apreciez foarte mult atenția la detalii și naturalețea interacțiunii. A fost exact ceea ce ne-am dorit pentru seara noastră de Crăciun.',
        author: {
          name: 'Radu Alexandru',
          rating: 5,
        },
      },
      {
        body: 'Servicii excelente! Moș Crăciun a fost punctual, prietenos și a creat o atmosferă minunată. Copiii încă vorbesc despre vizita lui.',
        author: {
          name: 'Laura Marin',
          rating: 5,
        },
      },
    ],
  ],
];

const StarRating = ({ rating }: { rating: number }) => {
  return (
    <div className="flex gap-1 mt-2">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          size={16}
          className={i < rating ? "fill-red-500 text-red-500" : "text-gray-300"}
        />
      ))}
    </div>
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Testimonials() {
  return (
    <div className="relative isolate bg-white pb-16 pt-0">
      {/* Gradient background decorations */}
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-red-500 to-red-800 sm:left-[calc(50%-30rem)]"
        />
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="relative left-[-20rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-red-500 to-red-800 xl:left-0 xl:mr-[calc(50%-12rem)]"
        />
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-base font-semibold leading-7 text-red-600">Testimoniale</h2>
          <p className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Ce spun clienții noștri despre experiența cu Moș Crăciun
          </p>
        </div>

        {/* Mobile Layout */}
        <div className="mt-8 space-y-4 sm:hidden">
          <figure className="rounded-2xl bg-gradient-to-br from-red-50 to-white p-1 shadow-lg">
            <div className="h-full rounded-xl bg-white p-6">
              <blockquote className="text-gray-900 text-lg">
                <p>{`"${featuredTestimonial.body}"`}</p>
              </blockquote>
              <figcaption className="mt-6">
                <div className="font-semibold text-red-700">{featuredTestimonial.author.name}</div>
                <StarRating rating={featuredTestimonial.author.rating} />
              </figcaption>
            </div>
          </figure>

          {testimonials.flat(2).map((testimonial, idx) => (
            <figure
              key={idx}
              className="rounded-2xl bg-gradient-to-br from-red-50 to-white p-1 shadow-lg"
            >
              <div className="h-full rounded-xl bg-white p-6">
                <blockquote className="text-gray-900">
                  <p>{`"${testimonial.body}"`}</p>
                </blockquote>
                <figcaption className="mt-6">
                  <div className="font-semibold text-red-700">{testimonial.author.name}</div>
                  <StarRating rating={testimonial.author.rating} />
                </figcaption>
              </div>
            </figure>
          ))}
        </div>

        {/* Desktop Layout */}
        <div className="hidden sm:block">
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm/6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
            <figure className="col-span-2 rounded-2xl bg-gradient-to-br from-red-50 to-white p-1 shadow-lg sm:col-span-2 xl:col-start-2 xl:row-end-1">
              <div className="h-full rounded-xl bg-white">
                <blockquote className="p-6 text-lg font-semibold tracking-tight text-gray-900 sm:p-12 sm:text-xl/8">
                  <p>{`"${featuredTestimonial.body}"`}</p>
                </blockquote>
                <figcaption className="flex flex-col gap-2 border-t border-gray-900/10 bg-gray-50 px-6 py-4 rounded-b-xl">
                  <div className="font-semibold text-red-700">{featuredTestimonial.author.name}</div>
                  <StarRating rating={featuredTestimonial.author.rating} />
                </figcaption>
              </div>
            </figure>

            {testimonials.map((columnGroup, columnGroupIdx) => (
              <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                {columnGroup.map((column, columnIdx) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) ||
                        (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                        ? 'xl:row-span-2'
                        : 'xl:row-start-1',
                      'space-y-8'
                    )}
                  >
                    {column.map((testimonial) => (
                      <figure
                        key={testimonial.author.name}
                        className="rounded-2xl bg-gradient-to-br from-red-50 to-white p-1 shadow-lg transition-transform hover:scale-105"
                      >
                        <div className="h-full rounded-xl bg-white p-6">
                          <blockquote className="text-gray-900">
                            <p>{`"${testimonial.body}"`}</p>
                          </blockquote>
                          <figcaption className="mt-6">
                            <div className="font-semibold text-red-700">{testimonial.author.name}</div>
                            <StarRating rating={testimonial.author.rating} />
                          </figcaption>
                        </div>
                      </figure>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}