import React, { useMemo } from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import CustomDatePicker from './CustomDatePicker';
import { Order } from '../../services/orderService';

type CharacterOption = {
  id: string | number;
  name: string;
};

interface FilterCriteria {
  character: string;
  clientType: string;
  searchTerm: string;
  startDate: string;
  endDate: string;
}

type OrderFiltersProps = {
  characters: CharacterOption[];
  clientTypes: string[];
  filterCriteria: FilterCriteria;
  setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCriteria>>;
  orders: Order[];
};

const OrderFilters: React.FC<OrderFiltersProps> = ({
  characters,
  clientTypes,
  filterCriteria,
  setFilterCriteria,
  orders
}) => {
  const sortByName = (a: CharacterOption, b: CharacterOption) => {
    const nameA = a.name.trim().toLowerCase();
    const nameB = b.name.trim().toLowerCase();
    return nameA.localeCompare(nameB, 'ro');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilterCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handleCharacterChange = (character: CharacterOption) => {
    setFilterCriteria(prev => ({ ...prev, character: character.id.toString() }));
  };

  const handleClientTypeChange = (clientType: string) => {
    setFilterCriteria(prev => ({ ...prev, clientType }));
  };

  const handleDateChange = (field: 'startDate' | 'endDate') => (date: string) => {
    setFilterCriteria(prev => ({ ...prev, [field]: date }));
  };

  // Pregătirea listei de caractere sortate
  const allCharactersOption = characters.find(c => c.id === '');
  const sortedCharacters = characters
    .filter(c => c.id !== '')
    .map(char => ({
      ...char,
      name: char.name.trim()
    }))
    .sort(sortByName);

  const finalCharactersList = allCharactersOption 
    ? [allCharactersOption, ...sortedCharacters]
    : sortedCharacters;

  const stats = useMemo(() => {
    const results = {
      totalOrders: orders.length,
      totalAmount: 0,
      totalModelFees: 0,
      totalDriverFees: 0,
      characterStats: {
        totalAmount: 0,
        totalOrders: 0,
        extraCharacterAppearances: 0,
      }
    };

    orders.forEach(order => {
      // Calculăm totalurile generale
      results.totalAmount += order.billingInfo.totalAmount;
      results.totalModelFees += order.billingInfo.modelFee;
      results.totalDriverFees += order.billingInfo.driverFee;

      // Dacă avem un character selectat, calculăm statisticile specifice pentru el
      if (filterCriteria.character) {
        const characterId = order.character._id;
        if (characterId === filterCriteria.character) {
          results.characterStats.totalAmount += order.billingInfo.modelFee;
          results.characterStats.totalOrders += 1;
        }

        // Verificăm și aparițiile ca personaj extra
        order.extraCharacters.forEach(extra => {
          const extraCharId = typeof extra.character === 'string' 
            ? extra.character 
            : extra.character._id;
            
          if (extraCharId === filterCriteria.character) {
            results.characterStats.totalAmount += extra.modelFee;
            results.characterStats.extraCharacterAppearances += 1;
          }
        });
      }
    });

    return results;
  }, [orders, filterCriteria.character]);

  return (
    <div className="mb-6">
      <div className="flex flex-col md:flex-row md:items-center md:space-x-4 space-y-4 md:space-y-0">
        {/* Search Input */}
        <div className="w-full md:w-1/4">
          <label htmlFor="searchTerm" className="block text-sm font-medium text-gray-700 mb-1">
            Căutare
          </label>
          <div className="relative rounded-md">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              name="searchTerm"
              id="searchTerm"
              value={filterCriteria.searchTerm}
              onChange={handleInputChange}
              className="block w-full rounded-md border border-gray-300 py-1.5 pl-10 pr-3 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Caută comenzi..."
            />
          </div>
        </div>

        {/* Character Selector */}
        <div className="w-full md:w-1/5">
          <Listbox 
            value={finalCharactersList.find(c => c.id.toString() === filterCriteria.character) || null} 
            onChange={handleCharacterChange}
          >
            <div className="relative">
              <Listbox.Label className="block text-sm font-medium text-gray-700 mb-1">
                Personaj
              </Listbox.Label>
              <Listbox.Button className="relative w-full sm:leading-6 cursor-default rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm">
                <span className="block truncate">
                  {finalCharactersList.find(c => c.id.toString() === filterCriteria.character)?.name || 'Selectează un personaj'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {finalCharactersList.map((character) => (
                  <Listbox.Option
                    key={character.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                      }`
                    }
                    value={character}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {character.name}
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>

        {/* Client Type Selector */}
        <div className="w-full md:w-1/5">
          <Listbox value={filterCriteria.clientType} onChange={handleClientTypeChange}>
            <div className="relative">
              <Listbox.Label className="block text-sm font-medium text-gray-700 mb-1">
                Tip Client
              </Listbox.Label>
              <Listbox.Button className="relative w-full cursor-default sm:leading-6 rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm">
                <span className="block truncate">
                  {filterCriteria.clientType || 'Selectează un tip de client'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {clientTypes.map((type) => (
                  <Listbox.Option
                    key={type}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                      }`
                    }
                    value={type}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {type}
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>

        {/* Date Inputs */}
        <div className="w-full md:w-1/6">
          <CustomDatePicker
            selectedDate={filterCriteria.startDate}
            onChange={handleDateChange('startDate')}
            label="Data Început"
            name="startDate"
            placeholder="ZZ.LL.AAAA"
          />
        </div>
        <div className="w-full md:w-1/6">
          <CustomDatePicker
            selectedDate={filterCriteria.endDate}
            onChange={handleDateChange('endDate')}
            label="Data Sfârșit"
            name="endDate"
            placeholder="ZZ.LL.AAAA"
          />
        </div>
      </div>

      {/* Statistici */}
      <div className="mt-6 bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Statistici pentru comenzile filtrate
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="bg-gray-50 p-4 rounded-md">
            <p className="text-sm text-gray-500">Total Comenzi</p>
            <p className="text-2xl font-semibold text-gray-900">{stats.totalOrders}</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-md">
            <p className="text-sm text-gray-500">Total Încasări</p>
            <p className="text-2xl font-semibold text-gray-900">{stats.totalAmount} RON</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-md">
            <p className="text-sm text-gray-500">Total Remunerații Modele</p>
            <p className="text-2xl font-semibold text-gray-900">{stats.totalModelFees} RON</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-md">
            <p className="text-sm text-gray-500">Total Remunerații Șoferi</p>
            <p className="text-2xl font-semibold text-gray-900">{stats.totalDriverFees} RON</p>
          </div>
        </div>

        {filterCriteria.character && (
          <div className="mt-6">
            <h4 className="text-md font-medium text-gray-900 mb-3">
              Statistici pentru personajul selectat
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-indigo-50 p-4 rounded-md">
                <p className="text-sm text-gray-500">Comenzi ca personaj principal</p>
                <p className="text-2xl font-semibold text-indigo-900">
                  {stats.characterStats.totalOrders}
                </p>
              </div>
              <div className="bg-indigo-50 p-4 rounded-md">
                <p className="text-sm text-gray-500">Apariții ca personaj extra</p>
                <p className="text-2xl font-semibold text-indigo-900">
                  {stats.characterStats.extraCharacterAppearances}
                </p>
              </div>
              <div className="bg-indigo-50 p-4 rounded-md">
                <p className="text-sm text-gray-500">Total câștiguri</p>
                <p className="text-2xl font-semibold text-indigo-900">
                  {stats.characterStats.totalAmount} RON
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderFilters;