import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Gift, Snowflake, Star } from 'lucide-react';

const locations = [
  'Sectorul 1', 'Sectorul 2', 'Sectorul 3', 'Sectorul 4', 
  'Sectorul 5', 'Sectorul 6', 'Ilfov'
];

const messages = [
  "🎅 O nouă **rezervare** a fost făcută în **{location}** acum **{time}**!\n\n🎄 Locurile se ocupă **rapid** – rezervă vizita **Moșului** și oferă copilului tău o seară **<red>magică de Ajun</red>**!"
];

const timeVariations = [
  'câteva minute',
  '2 minute',
  '3 minute',
  '4 minute',
  '5 minute',
  'un minut'
];

const StarEffect = () => (
  <>
    {[...Array(10)].map((_, i) => (
      <motion.div
        key={i}
        initial={{ 
          opacity: 0, 
          scale: 0.5,
          x: Math.random() * 200 - 100,
          y: Math.random() * 200 - 100
        }}
        animate={{ 
          opacity: [0, 1, 0],
          scale: [0.5, 1, 0.5],
          rotate: 360,
          transition: { 
            duration: Math.random() * 2 + 1,
            repeat: Infinity,
            delay: Math.random() * 2
          }
        }}
        className="absolute text-yellow-300 opacity-50"
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`
        }}
      >
        <Star size={Math.random() * 12 + 8} />
      </motion.div>
    ))}
  </>
);

const SnowflakeEffect = () => (
  <>
    {[...Array(20)].map((_, i) => (
      <motion.div
        key={i}
        initial={{ 
          opacity: 0, 
          y: -50,
          x: Math.random() * window.innerWidth 
        }}
        animate={{ 
          opacity: [0, 0.7, 0],
          y: window.innerHeight + 50,
          rotate: 360,
          transition: { 
            duration: Math.random() * 5 + 3,
            repeat: Infinity,
            delay: Math.random() * 3
          }
        }}
        className="absolute text-white/70 opacity-50"
        style={{
          left: `${Math.random() * 100}%`,
          top: `-50px`
        }}
      >
        <Snowflake size={Math.random() * 12 + 8} />
      </motion.div>
    ))}
  </>
);

interface AdNotificationProps {
  testMode?: boolean;
  isDialogOpen?: boolean;
}

const AdNotification = ({ testMode = false, isDialogOpen = false }: AdNotificationProps) => {
  const [notifications, setNotifications] = useState<Array<{
    id: number;
    message: string;
  }>>([]);
  const [lastCheck, setLastCheck] = useState(Date.now());

  const formatMessage = (message: string) => {
    const boldMessage = message.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    const coloredMessage = boldMessage.replace(/<red>(.*?)<\/red>/g, '<span class="text-red-600">$1</span>');
    return coloredMessage.replace(/\\n/g, '<br/>');
  };

  useEffect(() => {
    const checkForNewReservations = () => {
      const saved = localStorage.getItem('lastReservationCount');
      if (saved) {
        const { count, lastUpdate } = JSON.parse(saved);
        
        if (lastUpdate > lastCheck) {
          const location = locations[Math.floor(Math.random() * locations.length)];
          const time = timeVariations[Math.floor(Math.random() * timeVariations.length)];
          const messageTemplate = messages[Math.floor(Math.random() * messages.length)];
          
          const newNotification = {
            id: Date.now(),
            message: messageTemplate
              .replace('{location}', location)
              .replace('{time}', time)
          };
          
          setNotifications(prev => [newNotification, ...prev].slice(0, 3));
          setLastCheck(lastUpdate);

          setTimeout(() => {
            setNotifications(prev => 
              prev.filter(notification => notification.id !== newNotification.id)
            );
          }, 10000);
        }
      }
    };

    const interval = setInterval(
      checkForNewReservations, 
      testMode ? 2500 : 30000
    );

    return () => clearInterval(interval);
  }, [testMode, lastCheck]);

  if (isDialogOpen) return null;

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-30 overflow-hidden"
    >
      <StarEffect />
      <SnowflakeEffect />
      
      <div className="flex w-full flex-col items-center space-y-3 sm:items-end relative z-10">
        <AnimatePresence>
          {notifications.map(({id, message}) => (
            <motion.div
              key={id}
              initial={{ 
                opacity: 0,
                x: 100,
              }}
              animate={{ 
                opacity: 1,
                x: 0,
                transition: { 
                  type: "spring",
                  stiffness: 300,
                  damping: 25
                }
              }}
              exit={{ 
                opacity: 0,
                x: 300,
                transition: { 
                  duration: 0.5,
                  ease: "easeOut"
                }
              }}
              className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white/90 backdrop-blur-md shadow-lg ring-1 ring-red-500/20 relative"
            >
              <motion.div 
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ duration: 10 }}
                className="absolute bottom-0 left-0 right-0 h-0.5 bg-red-500 origin-left"
              />
              <div className="p-3 flex items-start space-x-3 relative">
                <motion.div 
                  animate={{
                    scale: [1, 1.2, 1],
                    rotate: [0, 10, -10, 0],
                    transition: { 
                      repeat: Infinity,
                      duration: 1.5
                    }
                  }}
                  className="shrink-0 mt-0.5"
                >
                  <Gift 
                    className="h-6 w-6 text-red-600" 
                    strokeWidth={1.5} 
                    aria-hidden="true" 
                  />
                </motion.div>
                
                <div className="flex-grow">
                  <p 
                    className="text-[13px] leading-5 tracking-tight text-gray-900 font-medium"
                    style={{
                      fontFeatureSettings: "'ss01' on, 'ss02' on, 'ss03' on",
                      wordSpacing: '-0.05em'
                    }}
                    dangerouslySetInnerHTML={{ 
                      __html: formatMessage(message) 
                    }}
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AdNotification;