import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  CalendarIcon,
  MapPinIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  InformationCircleIcon,
  ClockIcon,
  UserIcon,
  PhoneIcon,
  BuildingOfficeIcon,
  DocumentArrowDownIcon
} from '@heroicons/react/24/outline';
import ReactToPrint from 'react-to-print';
import {
  getCharacters,
  getCharacterDetailsAndOrders,
  Character,
  DetailedCharacterStats
} from '../../services/characterService';
import { formatInTimeZone } from 'date-fns-tz';
import { ro } from 'date-fns/locale';
import CharacterStatsDisplay from '../components/CharacterStatsDisplay';
import ModernPagination from '../components/ModernPagination';
import EarningsFilters from '../components/EarningsFilters';
import PrintableEarnings from '../components/PrintableEarnings';

interface EarningsPageProps {
  initialCharacterId?: string;
}

const ITEMS_PER_PAGE = 10;

const formatRomanianNumber = (value: number) => {
  const withDecimals = value.toFixed(2);
  const [integerPart, decimalPart] = withDecimals.split('.');
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `${formattedInteger},${decimalPart}`;
};

const EarningsPage: React.FC<EarningsPageProps> = ({ initialCharacterId }) => {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [selectedCharacter, setSelectedCharacter] = useState<Character | null>(null);
  const [detailedStats, setDetailedStats] = useState<DetailedCharacterStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDates, setFilterDates] = useState({ startDate: '', endDate: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [hideAmountInExport, setHideAmountInExport] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const fetchedCharacters = await getCharacters();
        const sortedCharacters = fetchedCharacters.sort((a, b) =>
          `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`, 'ro')
        );
        setCharacters(sortedCharacters);

        const initialSelection = initialCharacterId
          ? sortedCharacters.find(char => char._id === initialCharacterId)
          : sortedCharacters[0];

        setSelectedCharacter(initialSelection || null);
      } catch (err) {
        setError('Eroare la încărcarea personajelor');
      } finally {
        setLoading(false);
      }
    };

    fetchCharacters();
  }, [initialCharacterId]);

  useEffect(() => {
    const fetchStats = async () => {
      if (!selectedCharacter) return;
      try {
        setLoading(true);

        const response = await getCharacterDetailsAndOrders(selectedCharacter._id);

        let filteredOrders = [...response.detailedOrders];

        if (filterDates.startDate) {
          const startDate = new Date(filterDates.startDate);
          filteredOrders = filteredOrders.filter(order => 
            new Date(order.period.startDate) >= startDate
          );
        }

        if (filterDates.endDate) {
          const endDate = new Date(filterDates.endDate);
          filteredOrders = filteredOrders.filter(order => 
            new Date(order.period.startDate) <= endDate
          );
        }

        const sortedOrders = filteredOrders.sort((a, b) => {
          return new Date(a.period.startDate).getTime() - new Date(b.period.startDate).getTime();
        });

        const totalEarnings = filteredOrders.reduce(
          (sum, order) => sum + order.financials.characterEarnings, 
          0
        );

        const earningsAsMain = filteredOrders
          .filter(order => order.role === 'Main')
          .reduce((sum, order) => sum + order.financials.characterEarnings, 0);

        const earningsAsExtra = filteredOrders
          .filter(order => order.role === 'Extra')
          .reduce((sum, order) => sum + order.financials.characterEarnings, 0);

        setDetailedStats({
          totalOrders: filteredOrders.length,
          totalEarnings,
          earningsAsMain,
          earningsAsExtra,
          // Adăugăm calculul pentru totalul șoferilor
          driverEarnings: filteredOrders.reduce(
            (sum, order) => sum + (order.financials.driverEarnings || 0), 
            0
          ),
          detailedOrders: sortedOrders
        });

        setCurrentPage(1);
      } catch (err) {
        console.error('Eroare la încărcarea statisticilor:', err);
        setError('Eroare la încărcarea statisticilor');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [selectedCharacter, filterDates]);

  const currentOrders = useMemo(() => {
    if (!detailedStats?.detailedOrders) return [];
    const indexOfLastOrder = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstOrder = indexOfLastOrder - ITEMS_PER_PAGE;
    return detailedStats.detailedOrders.slice(indexOfFirstOrder, indexOfLastOrder);
  }, [detailedStats, currentPage]);

  const totalPages = useMemo(() => {
    return Math.ceil((detailedStats?.detailedOrders?.length || 0) / ITEMS_PER_PAGE);
  }, [detailedStats]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error) {
    return <div className="p-4 text-red-600 bg-red-50 rounded-md">{error}</div>;
  }

  const totalOrdersCount = detailedStats?.detailedOrders?.length || 0;
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex = Math.min(currentPage * ITEMS_PER_PAGE, totalOrdersCount);

  return (
    <div className="p-0">
      <div className="sm:flex sm:items-center sm:justify-between mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">
            Raport Venituri
            {selectedCharacter && ` - ${selectedCharacter.firstName} ${selectedCharacter.lastName}`}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Listă detaliată cu toate veniturile și comenzile modelului.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:flex-none flex items-center gap-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={hideAmountInExport}
              onChange={(e) => setHideAmountInExport(e.target.checked)}
              className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <span className="text-sm text-gray-700">
              Ascunde total comandă în export
            </span>
          </label>
          <ReactToPrint
            trigger={() => (
              <button className="inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white bg-indigo-600 hover:bg-indigo-500">
                <DocumentArrowDownIcon className="h-5 w-5 mr-2" />
                Exportă PDF
              </button>
            )}
            content={() => printRef.current}
          />
        </div>
      </div>

      <EarningsFilters
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        selectedCharacter={selectedCharacter}
        onCharacterChange={setSelectedCharacter}
        characters={characters}
        filterDates={filterDates}
        onDateChange={(type, date) => setFilterDates(prev => ({ ...prev, [type]: date }))}
      />

      {detailedStats && detailedStats.detailedOrders && (
        <CharacterStatsDisplay
          stats={{
            totalOrders: detailedStats.totalOrders,
            totalAmount: detailedStats.detailedOrders.reduce(
              (sum, order) => sum + order.financials.orderTotal,
              0
            ),
            characterEarnings: detailedStats.totalEarnings,
            // Adăugăm și driverEarnings
            driverEarnings: detailedStats.driverEarnings
          }}
        />
      )}

      {detailedStats?.detailedOrders?.length ? (
        <div className="mt-8 flow-root" ref={printRef}>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 print:hidden">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Data și Ora
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Client
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Locație
                    </th>
                    <th className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                      Total Comandă
                    </th>
                    <th className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                      Câștig Model
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Șofer
                    </th>
                    <th className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                      Câștig Șofer
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {currentOrders.map((order) => (
                    <tr key={order.orderId} className="hover:bg-gray-50">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <CalendarIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <span>
                              {formatInTimeZone(
                                order.period.startDate,
                                'Europe/Bucharest',
                                'dd MMMM yyyy',
                                { locale: ro }
                              )}
                            </span>
                          </div>
                          <div className="flex items-center mt-1 text-gray-500">
                            <ClockIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <span>
                              {order.period.startTime} - {order.period.endTime}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <UserIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <span>{order.client.name || 'Client Anonim'}</span>
                          </div>
                          {order.client.phone && (
                            <div className="flex items-center mt-1">
                              <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" />
                              <span>{order.client.phone}</span>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <MapPinIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <span>{order.location.address || 'Adresă nespecificată'}</span>
                          </div>
                          {order.location.zone && (
                            <div className="flex items-center mt-1">
                              <BuildingOfficeIcon className="h-5 w-5 text-gray-400 mr-2" />
                              <span>{order.location.zone}</span>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-right">
                        <div className="flex items-center justify-end">
                          <BanknotesIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <span className="font-medium">
                            {formatRomanianNumber(order.financials.orderTotal)} RON
                          </span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-right">
                        <div className="flex items-center justify-end">
                          <CurrencyDollarIcon className="h-5 w-5 text-green-500 mr-2" />
                          <span className="font-medium text-green-600">
                            {formatRomanianNumber(order.financials.characterEarnings)} RON
                          </span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="flex flex-col">
                          {order.driver ? (
                            <>
                              <div className="flex items-center">
                                <UserIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span>{order.driver.name}</span>
                              </div>
                              <div className="flex items-center mt-1">
                                <PhoneIcon className="h-5 w-5 text-gray-400 mr-2" />
                                <span>{order.driver.phone}</span>
                              </div>
                            </>
                          ) : (
                            <span className="text-gray-400">Fără șofer</span>
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-right">
                        <div className="flex items-center justify-end">
                          <CurrencyDollarIcon className="h-5 w-5 text-blue-500 mr-2" />
                          <span className="font-medium text-blue-600">
                            {formatRomanianNumber(order.financials.driverEarnings || 0)} RON
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {selectedCharacter && detailedStats?.detailedOrders && (
            <PrintableEarnings
              orders={detailedStats.detailedOrders}
              generatedBy={selectedCharacter}
              hideTotalAmount={hideAmountInExport}
              filterDates={filterDates}
            />
          )}
        </div>
      ) : (
        <div className="mt-8 text-center">
          <div className="rounded-lg border-2 border-dashed border-gray-300 p-12">
            <CurrencyDollarIcon className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">Nu există comenzi</h3>
            <p className="mt-1 text-sm text-gray-500">
              Nu au fost găsite comenzi pentru perioada selectată.
            </p>
          </div>
        </div>
      )}

      <div className="mt-4 flex justify-between items-center print:hidden">
        <div className="text-sm text-gray-700">
          Afișare {startIndex} - {endIndex} din {totalOrdersCount} rezultate
        </div>
        <ModernPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default EarningsPage;
