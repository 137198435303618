import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { FaWhatsapp } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import OfferBanner from './OfferBanner'

const navigation = [
  { name: 'Acasa', href: '/' },
  { name: 'Despre Noi', href: '/?section=despre-noi' },
  { name: 'Servicii', href: '/?section=servicii' },
  { name: 'Portofoliu', href: '/portfolio' },
  { name: 'Contact', href: '/contact' },
]

const whatsappLink = "https://wa.me/40748161436?text=Bună ziua! Aș dori să închiriez un Moș Crăciun pentru un eveniment."

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="relative">
      {/* Offer Banner */}
      <OfferBanner />

      {/* Navbar */}
      <header className="bg-white lg:mx-4 lg:-mt-4 rounded-[1rem]">
        <nav className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8" aria-label="Global">
          <div className="flex items-center justify-between py-6">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">InchiriazaMosCraciun</span>
                <img
                  className="h-16 w-auto"
                  src="/logoinchiriazamoscraciun.png"
                  alt="InchiriazaMosCraciun.ro"
                />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <Link key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-gray-900 hover:text-gray-600">
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-[#006039] px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#00754a] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#006039] flex items-center"
              >
                <FaWhatsapp className="h-5 w-5 mr-2" />
                Închiriază Acum
              </a>
            </div>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">InchiriazaMosCraciun</span>
                <img
                  className="h-16 w-auto"
                  src="/logoinchiriazamoscraciun.png"
                  alt="InchiriazaMosCraciun.ro"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex items-center"
                  >
                    <FaWhatsapp className="h-5 w-5 mr-2" />
                    Închiriază Acum
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  )
}