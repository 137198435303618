import React from 'react';
import { Star, Target, HandHeart } from 'lucide-react';

const features = [
  {
    name: 'De ce donăm',
    description: 'Pentru unii, Crăciunul înseamnă căldură și familie; pentru alții, este doar un vis îndepărtat. Credem că fiecare familie merită bucuria sărbătorilor. De aceea, donăm ',
    highlight: '10% din profitul fiecărei comenzi',
    descriptionEnd: ' pentru a aduce speranță celor care au cea mai mare nevoie.',
    href: '#',
    icon: Star,
  },
  {
    name: 'Scopul nostru',
    description: 'Vrem să facem Crăciunul mai luminos pentru toți. Cu fiecare vizită a lui Moș Crăciun, trimitem un mesaj de iubire și sprijin. Împreună putem schimba realitatea celor care trăiesc în lipsuri, aducându-le o rază de lumină.',
    href: '#',
    icon: Target,
  },
  {
    name: 'Alătură-te misiunii',
    description: 'Căutăm parteneri care să creadă în puterea de a face bine. Dacă reprezinți o asociație socială, hai să lucrăm împreună! Împreună putem transforma Crăciunul în realitate pentru cei care speră la o minune.',
    href: '/contact',
    icon: HandHeart,
  },
];

const CampaignAbout = () => {
  return (
    <section className="relative bg-white my-24">
      <div className="relative isolate px-6">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold text-[#c41e3a]">Magia sărbătorilor care schimbă vieți</h2>
            <p className="mt-2 text-4xl font-display tracking-tight text-gray-900 sm:text-5xl">
              Despre Inițiativă
            </p>
            <p className="mt-6 text-lg text-gray-600">
              Această inițiativă transformă fiecare vizită a lui Moș Crăciun într-o șansă de a aduce speranță. 
              Pentru unii, este o bucurie să împartă magia Crăciunului; pentru alții, este lumina care le încălzește inimile 
              în cele mai dificile momente. Împreună, facem sărbătorile mai calde pentru toți.
            </p>
          </div>

          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold text-gray-900">
                    <feature.icon className="h-6 w-6 flex-none text-[#c41e3a]" aria-hidden="true" />
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base text-gray-600">
                    {feature.highlight ? (
                      <p className="flex-auto">
                        {feature.description}
                        <span className="text-[#c41e3a] font-semibold">{feature.highlight}</span>
                        {feature.descriptionEnd}
                      </p>
                    ) : (
                      <p className="flex-auto">{feature.description}</p>
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* Bottom decoration */}
        <div
          aria-hidden="true"
          className="absolute inset-x-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ffcdd2] to-[#c41e3a] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </section>
  );
};

export default CampaignAbout;