import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './public/pages/Home';
import Login from './public/pages/Login';
import Dashboard from './admin/pages/Dashboard';
import CharacterOrdersPanel from './admin/pages/CharacterOrdersPanel';
import MosCraciunAcasa from './public/pages/MosCraciunAcasa';
import MosCraciunCampanii from './public/pages/MosCraciunCampanii';
import MosCraciunCompanii from './public/pages/MosCraciunCompanii';
import MosCraciunSerbari from './public/pages/MosCraciunSerbari';
import MosCraciunInfluenceri from './public/pages/MosCraciunInfluenceri';
import Portfolio from './public/pages/Portfolio';
import ContactPage from './public/pages/Contact';
import Soon from './public/pages/Soon';
import PrivateRoute from './shared/PrivateRoute';
import { NotificationProvider } from './admin/components/Notification';
import ChristmasCampaignPopup from './public/components/ChristmasCampaignPopup';
import ChristmasMessageDialog from './public/components/ChristmasMessageDialog';
import DaruimImpreuna from './public/pages/DaruimImpreuna';
import 'react-datepicker/dist/react-datepicker.css';
import CookieManager from './public/components/CookieBanner';
import CookiePolicy from './public/pages/PoliticaCookie';
import PrivacyPolicy from './public/pages/PoliticaConfidentialitate';
import TermsAndConditions from './public/pages/TermeniSiConditii';
import AdNotification from './public/components/AdNotification';

const AppContent = () => {
  const location = useLocation();
  const maintenanceMode = false;
  const showLastMinute = false;
  const testMode = false;
  
  const excludedPages = [
    '/login',
    '/dashboard',
    '/panou',
    '/politica-cookie',
    '/politica-de-confidentialitate',
    '/termeni-si-conditii',
    '/contact'
  ];

  const isExcludedPage = excludedPages.includes(location.pathname) || 
    location.pathname.startsWith('/dashboard/') || 
    location.pathname.startsWith('/panou/');

  return (
    <div className="app-container">
      <Routes>
        {maintenanceMode ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Soon />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/politica-cookie" element={<CookiePolicy />} />
            <Route path="/politica-de-confidentialitate" element={<PrivacyPolicy />} />
            <Route path="/termeni-si-conditii" element={<TermsAndConditions />} />
            <Route path="/mos-craciun-acasa" element={<MosCraciunAcasa />} />
            <Route path="/mos-craciun-campanii" element={<MosCraciunCampanii />} />
            <Route path="/mos-craciun-companii" element={<MosCraciunCompanii />} />
            <Route path="/mos-craciun-serbare" element={<MosCraciunSerbari />} />
            <Route path="/mos-craciun-influenceri" element={<MosCraciunInfluenceri />} />
            <Route path="/daruim-impreuna" element={<DaruimImpreuna />} />
            
            {/* Ruta pentru admin dashboard */}
            <Route
              path="/dashboard/*"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            {/* Ruta pentru panoul caracterelor */}
            <Route
              path="/panou/*"
              element={
                <PrivateRoute>
                  <Routes>
                    <Route path="comenzile-mele" element={<CharacterOrdersPanel />} />
                    <Route path="*" element={<Navigate to="/panou/comenzile-mele" replace />} />
                  </Routes>
                </PrivateRoute>
              }
            />
            
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </Routes>

      {!maintenanceMode && !isExcludedPage && (
        <>
          <AdNotification testMode={testMode} />
          <ChristmasMessageDialog />
        </>
      )}
      {!maintenanceMode && showLastMinute && !isExcludedPage && <ChristmasCampaignPopup />}
      <CookieManager />
    </div>
  );
};

function App() {
  return (
    <HelmetProvider>
      <NotificationProvider>
        <Router>
          <AppContent />
        </Router>
      </NotificationProvider>
    </HelmetProvider>
  );
}

export default App;