import React, { useEffect, useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { FaWhatsapp } from 'react-icons/fa';
import CharacterCards from './CharactersCards';

const Hero = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/40748161436`, '_blank');
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:madalina@inchiriazamoscraciun.ro';
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const svgElement = document.querySelector('#animated-svg path') as SVGPathElement | null;
         
    if (svgElement) {
      const totalLength = svgElement.getTotalLength();
      svgElement.style.strokeDasharray = totalLength.toString();
      svgElement.style.strokeDashoffset = totalLength.toString();
       
      const animateSVG = () => {
        svgElement.style.transition = 'stroke-dashoffset 2s ease-in-out';
        svgElement.style.strokeDashoffset = '0';
      };
       
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setTimeout(animateSVG, 500);
            observer.disconnect();
          }
        });
      }, { threshold: 0.1 });
       
      observer.observe(svgElement);
       
      return () => observer.disconnect();
    }
  }, []);

  return (
    <>
      <div className="relative pb-16 pt-10 lg:pt-20 overflow-hidden">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="mx-auto max-w-4xl font-display text-4xl font-medium tracking-tight text-slate-900 sm:text-6xl md:text-7xl">
            Cautarea unui{' '}
            <span className="relative whitespace-nowrap text-[#036039]">
              <svg
                id="animated-svg"
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute left-0 top-2/3 h-[0.58em] w-full fill-none stroke-[#036039] stroke-2"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
              </svg>
              <span className="relative">
                <span className="santa-hat-container">
                  Mo
                  <img
                    src="/img/santahat.png"
                    alt="Santa Hat"
                    className="santa-hat"
                  />
                  <span className="relative z-10">ș</span>
                </span>{' '}
                Craciun
              </span>
            </span>{' '}
            de inchiriat a luat sfârsit!
          </h1>
          <p className="mx-auto mt-4 max-w-2xl text-lg tracking-tight text-slate-700">
            La noi nu vei gasi niste domni simpatici deghizati in Mos Craciun, de Sarbatori, ci niste Mosi Craciun autentici deghizati in domni simpatici tot restul anului.
          </p>
          <div className="mt-8 flex justify-center gap-x-6">
            <button
              onClick={() => setIsDialogOpen(true)}
              className="group inline-flex items-center justify-center rounded-full py-3 px-6 text-base font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-[#036039] text-white hover:bg-[#004c2a] hover:text-slate-100 active:bg-[#03552e] active:text-slate-300 focus-visible:outline-[#036039] transition-all duration-200 ease-in-out transform hover:scale-105"
            >
              Rezerva acum!
            </button>
          </div>
        </div>
        <div className="mt-16 w-full">
          <p className="font-display text-base text-slate-900 text-center mb-4">
            Inchriere Mosi Craciun cu barba naturala pentru Bucuresti si Ilfov.
          </p>
          <CharacterCards />
        </div>
      </div>

      <Dialog 
        open={isDialogOpen} 
        onClose={() => setIsDialogOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-red-100">
                  <img src="/santa-contact.png" alt="Santa Contact" className="h-20 w-20" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                    Alege modalitatea de contact
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Pentru o rezervare rapidă și ușoară, te încurajăm să ne contactezi prin WhatsApp. 
                      Dacă preferi email-ul, te rugăm să ne scrii și vom reveni cu un răspuns cât mai curând posibil.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  onClick={handleWhatsAppClick}
                  className="inline-flex w-full justify-center items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                >
                  <FaWhatsapp className="mr-2 h-5 w-5" />
                  WhatsApp
                </button>
                <button
                  type="button"
                  onClick={handleEmailClick}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                >
                  Email
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Hero;