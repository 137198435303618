import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FireIcon, SparklesIcon, ClockIcon, NoSymbolIcon, XMarkIcon, CalendarDateRangeIcon } from '@heroicons/react/24/solid';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { FaWhatsapp } from 'react-icons/fa';

export default function ChristmasServicesBento() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedService, setSelectedService] = useState('');

  const handleReserveClick = (service: string) => {
    setSelectedService(service);
    setIsDialogOpen(true);
  };

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/40748161436', '_blank');
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:madalina@inchiriazamoscraciun.ro';
    setIsDialogOpen(false);
  };

  return (
    <>
      <div className="bg-white pt-0 pb-16 sm:pb-24 sm:pt-0 md:py-24 md:pt-0">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <p className="mt-2 max-w-4xl text-center text-pretty text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl mx-auto">
            Tot ce ai nevoie pentru un Crăciun de neuitat
          </p>
          <p className="mt-6 text-lg text-center text-gray-600 mx-auto max-w-2xl">
            Descoperă serviciile noastre unice de Crăciun, concepute pentru a aduce magia sărbătorilor în casa ta, la evenimentele tale și în comunitatea ta.
          </p>
          
          <div className="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
            {/* Card Principal - Moș Crăciun Acasă */}
            <div className="relative lg:col-span-3">
              <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
                {/* Badge-uri Superioare */}
                <div className="absolute top-4 left-4 z-20 flex gap-2">
                  <span className="inline-flex items-center gap-1 rounded-full bg-red-50 px-3 py-1 text-xs font-semibold text-red-600 border border-red-100 animate-pulse">
                    <FireIcon className="h-3 w-3" />
                    Cerere Mare
                  </span>
                  <span className="inline-flex items-center gap-1 rounded-full bg-amber-50 px-3 py-1 text-xs font-semibold text-amber-600 border border-amber-100">
                    <SparklesIcon className="h-3 w-3" />
                    Popular
                  </span>
                </div>
                
                {/* Banner Urgent */}
                <div className="absolute top-8 right-0 z-20 bg-red-600 text-white px-6 py-1 text-xs font-bold transform rotate-45 translate-x-8 text-center">
                  Rezervă din timp!
                </div>

                <img
                  alt="Vizită Moș Crăciun"
                  src="/smoscraciunacasa.jpg"
                  className="h-80 w-full object-cover object-top"
                />
                <div className="p-10 bg-gradient-to-b from-white to-red-50 flex-1">
                  <div className="flex items-center gap-2 mb-3">
                    <ClockIcon className="h-4 w-4 text-red-600" />
                    <span className="text-xs font-medium text-red-600">Ultimele locuri disponibile!</span>
                  </div>
                  <h3 className="text-sm/4 font-semibold text-red-600">Moș Crăciun cu barbă naturală</h3>
                  <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Magia Crăciunului la tine acasă</p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    Rezervă-l acum și asigură-te că anul acesta Moș Crăciun va aduce nu doar cadouri, ci și cea mai frumoasă amintire de Crăciun pentru întreaga familie!
                  </p>
                  <div className="mt-4 flex gap-4 items-center">
                    <button
                      onClick={() => handleReserveClick('acasa')}
                      className="inline-flex items-center px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-lg hover:bg-red-500 transition-colors"
                    >
                      Rezervă acum
                      <span className="ml-2">→</span>
                    </button>
                    <Link 
                      to="/mos-craciun-acasa" 
                      className="inline-flex items-center text-sm font-semibold text-red-600 hover:text-red-500 transition-colors"
                    >
                      Află mai multe
                    </Link>
                  </div>
                </div>
              </div>
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
            </div>

            {/* Card Evenimente Corporative */}
            <div className="relative lg:col-span-3">
              <div className="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]" />
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
                <div className="absolute top-4 left-4 z-20 flex gap-2">
                  <span className="inline-flex items-center gap-1 rounded-full bg-red-50 px-3 py-1 text-xs font-semibold text-red-600 border border-red-100 animate-pulse">
                    <FireIcon className="h-3 w-3" />
                    Cerere Mare
                  </span>
                </div>
                <div className="absolute top-8 right-0 z-20 bg-red-600 text-white px-6 py-1 text-xs font-bold transform rotate-45 translate-x-8 text-center">
                  Rezervă din timp!
                </div>
                <img
                  alt="Evenimente corporative"
                  src="/moscraciuncompanii.webp"
                  className="h-80 w-full object-cover object-left lg:object-right"
                />
                <div className="p-10 bg-gradient-to-b from-white to-red-50 flex-1">
                  <div className="flex items-center gap-2 mb-3">
                    <ClockIcon className="h-4 w-4 text-red-600" />
                    <span className="text-xs font-medium text-red-600">Ultimele locuri disponibile!</span>
                  </div>
                  <h3 className="text-sm/4 font-semibold text-red-600">Moș Crăciun autentic</h3>
                  <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Pentru evenimentul sau campania ta</p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    Nu lăsa Magia Crăciunului să treacă neobservată! Contactează-ne acum și adu bucuria sărbătorilor la evenimentul sau campania ta de Crăciun!
                  </p>
                  <div className="mt-4 flex gap-4 items-center">
                    <button
                      onClick={() => handleReserveClick('companii')}
                      className="inline-flex items-center px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-lg hover:bg-red-500 transition-colors"
                    >
                      Rezervă acum
                      <span className="ml-2">→</span>
                    </button>
                    <Link 
                      to="/mos-craciun-companii" 
                      className="inline-flex items-center text-sm font-semibold text-red-600 hover:text-red-500 transition-colors"
                    >
                      Află mai multe
                    </Link>
                  </div>
                </div>
              </div>
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]" />
            </div>

            {/* Card Serbări - Epuizat */}
            <div className="relative lg:col-span-2">
              <div className="absolute inset-px rounded-lg bg-gray-100/80 lg:rounded-bl-[2rem]" />
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-bl-[calc(2rem+1px)]">
                {/* Badge-uri de status */}
                <div className="absolute top-4 left-4 z-20 flex gap-2">
                  <span className="inline-flex items-center gap-1 rounded-full bg-gray-100 px-3 py-1 text-xs font-semibold text-gray-600 border border-gray-200">
                    <NoSymbolIcon className="h-3 w-3" />
                    Epuizat
                  </span>
                </div>
                
                {/* Bandă diagonală */}
                <div className="absolute top-8 right-0 z-20 bg-gray-600 text-white px-6 py-1 text-xs font-bold transform rotate-45 translate-x-8 text-center">
                  Epuizat pentru 2024!
                </div>
                
                {/* Imagine cu overlay */}
                <div className="relative">
                  <img
                    alt="Livrare cadouri"
                    src="/moscraciunserbare.webp"
                    className="h-80 w-full object-cover object-left grayscale"
                  />
                  <div className="absolute inset-0 bg-gray-900/40" />
                </div>
                
                <div className="p-10 bg-gradient-to-b from-white to-gray-50 flex-1">
                  <div className="flex items-center gap-2 mb-3">
                    <CalendarDateRangeIcon className="h-4 w-4 text-gray-600" />
                    <span className="text-xs font-medium text-gray-600">Ne vedem în 2025!</span>
                  </div>
                  
                  <h3 className="text-sm/4 font-semibold text-gray-600">Momente Magice</h3>
                  <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-500">
                    Serbarea ta, o poveste de Crăciun
                  </p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-500">
                    Toate locurile pentru sezonul 2024 au fost epuizate. Vă mulțumim pentru încredere și vă așteptăm cu drag anul viitor pentru noi momente magice!
                  </p>
                  
                  <div className="mt-4 flex gap-4 items-center">
                    <button
                      disabled
                      className="inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-400 bg-gray-100 rounded-lg cursor-not-allowed"
                    >
                      Epuizat
                      <XMarkIcon className="ml-2 h-4 w-4" />
                    </button>
                    <Link
                      to="/mos-craciun-serbare"
                      className="inline-flex items-center text-sm font-semibold text-gray-600 hover:text-gray-500 transition-colors"
                    >
                      Vezi detalii
                    </Link>
                  </div>
                </div>
              </div>
              
              {/* Overlay de inactivitate */}
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
            </div>

            {/* Card Influenceri */}
            <div className="relative lg:col-span-2">
              <div className="absolute inset-px rounded-lg bg-white" />
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                <img
                  alt="Ședințe foto"
                  src="/moscraciuninfluenceri.webp"
                  className="h-80 w-full object-cover object-center"
                />
                <div className="p-10 flex-1">
                  <h3 className="text-sm/4 font-semibold text-red-600">Conținut de Poveste cu Moș Crăciun</h3>
                  <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Fii Creatorul Magiei de Crăciun!</p>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    Hai să facem din Crăciunul acesta o poveste minunată, plină de zâmbete și magie, pentru tine și pentru comunitatea ta!
                  </p>
                  <span className="mt-4 inline-block text-sm font-semibold text-gray-400 cursor-not-allowed">Serviciu indisponibil</span>
                </div>
              </div>
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
            </div>

            {/* Card Caritabil */}
            <div className="relative lg:col-span-2">
              <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
              <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
                <img
                  alt="Ateliere pentru copii"
                  src="/moscraciuncaritabil.webp"
                  className="h-80 w-full object-cover object-center"
                />
                <div className="p-10 flex-1">
                  <h3 className="text-sm/4 font-semibold text-red-600">Împreună pentru un Crăciun mai Bun</h3>
                  <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    Moș Crăciun vine fără costuri, cu inima deschisă, pentru a fi parte din povestea voastră de Crăciun!
                  </p>
                  <span className="mt-4 inline-block text-sm font-semibold text-gray-400 cursor-not-allowed">Serviciu indisponibil</span>
                </div>
              </div>
              <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
            </div>
          </div>
        </div>
      </div>

      {/* Dialog pentru rezervare */}

      <Dialog 
        open={isDialogOpen} 
        onClose={() => setIsDialogOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-red-100">
                  <img src="/santa-contact.png" alt="Santa Contact" className="h-20 w-20" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                    Alege modalitatea de contact
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Pentru o rezervare rapidă și ușoară, te încurajăm să ne contactezi prin WhatsApp. 
                      Dacă preferi email-ul, te rugăm să ne scrii și vom reveni cu un răspuns cât mai curând posibil.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  onClick={handleWhatsAppClick}
                  className="inline-flex w-full justify-center items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                >
                  <FaWhatsapp className="mr-2 h-5 w-5" />
                  WhatsApp
                </button>
                <button
                  type="button"
                  onClick={handleEmailClick}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                >
                  Email
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}