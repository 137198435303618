import { XMarkIcon } from '@heroicons/react/20/solid';
import { useState, useEffect } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { FaWhatsapp } from 'react-icons/fa';

const SnowEffect = () => (
  <div className="absolute inset-0 pointer-events-none overflow-hidden">
    {[...Array(40)].map((_, i) => (
      <div
        key={i}
        className="absolute rounded-full animate-snowfall"
        style={{
          left: `${Math.random() * 100}%`,
          width: `${Math.random() * 8 + 4}px`,
          height: `${Math.random() * 8 + 4}px`,
          background: 'radial-gradient(circle at center, white 60%, transparent)',
          filter: 'blur(1px)',
          opacity: 0,
          animationDelay: `${Math.random() * 3}s`,
          animationDuration: `${Math.random() * 4 + 3}s`
        }}
      />
    ))}
  </div>
);

const useReservationsCounter = (testMode = false) => {
  const [reservations, setReservations] = useState<number>(() => {
    // Ștergem vechiul localStorage pentru a forța noua valoare
    localStorage.removeItem('lastReservationCount');
    
    const now = new Date();
    const isChristmasEve = now.getDate() === 24 && now.getMonth() === 11;
    const isBeforeMidnight = now.getHours() < 24;
    
    if (isChristmasEve && isBeforeMidnight) {
      return 24; // Valoare mare pentru 24 decembrie
    } else {
      return 8; // Valoare pentru 25 decembrie
    }
  });

  useEffect(() => {
    const updateReservations = () => {
      const now = new Date();
      const isBeforeMidnight = now.getHours() < 24;
      
      if (!isBeforeMidnight) {
        setReservations(8); // Număr fix pentru 25 decembrie
        return;
      }

      const newCount = testMode ? 
        Math.max(0, reservations - Math.floor(Math.random() * 2)) :
        Math.max(15, reservations - Math.floor(Math.random() * 2));

      setReservations(newCount);
      
      localStorage.setItem('lastReservationCount', JSON.stringify({
        count: newCount,
        timestamp: new Date().toISOString(),
        lastUpdate: Date.now()
      }));
    };

    const interval = setInterval(updateReservations, testMode ? 5000 : 900000); // La 15 minute
    updateReservations();

    return () => clearInterval(interval);
  }, [testMode, reservations]);

  return reservations;
};

const ChristmasMessage = ({ onReserveClick }: { onReserveClick: () => void }) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
    isBeforeMidnight: true
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const midnight = new Date();
      midnight.setHours(24, 0, 0, 0);
      const difference = midnight.getTime() - now.getTime();

      if (difference <= 0) {
        return {
          hours: 0,
          minutes: 0,
          seconds: 0,
          isBeforeMidnight: false
        };
      }

      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        isBeforeMidnight: true
      };
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (!timeLeft.isBeforeMidnight) {
    return (
      <div className="flex flex-col sm:flex-row items-center gap-4">
        <p className="text-sm text-white font-medium">
          ✨ Crăciun Fericit! Moș Crăciun este pregătit să aducă bucurie și în data de 25 decembrie! ✨
        </p>
        <button
          onClick={onReserveClick}
          className="rounded-full bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm 
                   hover:bg-red-500 focus-visible:outline-red-600"
        >
          Rezervă pentru mâine
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col sm:flex-row items-center gap-4">
      <p className="text-sm text-white font-medium">
        ⏰ Mai sunt doar {timeLeft.hours}h:{timeLeft.minutes}m:{timeLeft.seconds}s până la marea noapte! 
        Moș Crăciun și spiridușii săi distribuie deja cadouri! ✨
      </p>
      <button
        onClick={onReserveClick}
        className="rounded-full bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm 
                 hover:bg-red-500 focus-visible:outline-red-600"
      >
        Contactează-ne acum
      </button>
    </div>
  );
};

interface HolidayBannerProps {
  testMode?: boolean;
}

export default function HolidayBanner({ testMode = false }: HolidayBannerProps) {
  const [isVisible, setIsVisible] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const reservations = useReservationsCounter(testMode);
  const now = new Date();
  const isBeforeMidnight = now.getHours() < 24;

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/40748161436`, '_blank');
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:madalina@inchiriazamoscraciun.ro';
    setIsDialogOpen(false);
  };

  if (!isVisible) return null;

  return (
    <>
      <div className="relative flex flex-col items-center gap-y-4 bg-[#0a1526] px-6 py-3 sm:px-3.5 overflow-hidden">
        <SnowEffect />
        <div className="relative z-10 flex flex-col sm:flex-row items-center gap-4">
          <p className="text-sm text-white text-center sm:text-left">
            {isBeforeMidnight ? (
              <>
                🎄 Magia Crăciunului este aici! Încă mai avem <span className="text-red-600 font-bold">{reservations}</span> locuri disponibile pentru această seară specială! 
                <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline size-0.5 fill-current">
                  <circle r={1} cx={1} cy={1} />
                </svg>
                🎁 Oferă-i copilului tău o amintire magică cu Moș Crăciun! ✨
              </>
            ) : (
              <>
                🎄 Crăciun Fericit! Mai sunt doar <span className="text-red-600 font-bold">{reservations}</span> locuri pentru ziua de mâine! 
                <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline size-0.5 fill-current">
                  <circle r={1} cx={1} cy={1} />
                </svg>
                🎁 Moș Crăciun poate aduce bucurie și mâine! ✨
              </>
            )}
          </p>
        </div>
        
        <ChristmasMessage onReserveClick={() => setIsDialogOpen(true)} />

        <div className="absolute top-2 right-2 z-10">
          <button
            type="button"
            onClick={() => setIsVisible(false)}
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          >
            <span className="sr-only">Închide</span>
            <XMarkIcon className="size-5 text-white/70 hover:text-white transition-colors" aria-hidden="true" />
          </button>
        </div>

        <div className="absolute bottom-0 left-0 right-0 h-1 neon-effect"></div>
      </div>

      <Dialog 
        open={isDialogOpen} 
        onClose={() => setIsDialogOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div>
                <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-red-100">
                  <img src="/santa-contact.png" alt="Santa Contact" className="h-20 w-20" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                    {isBeforeMidnight ? 'Contactează-ne rapid!' : 'Rezervă pentru mâine!'}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {isBeforeMidnight 
                        ? 'Pentru o programare în această seară specială, contactează-ne acum prin WhatsApp pentru cel mai rapid răspuns!'
                        : 'Încă mai poți rezerva o vizită magică pentru ziua de mâine! Contactează-ne pentru detalii și disponibilitate.'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  onClick={handleWhatsAppClick}
                  className="inline-flex w-full justify-center items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline-green-600 sm:col-start-2"
                >
                  <FaWhatsapp className="mr-2 h-5 w-5" />
                  WhatsApp
                </button>
                <button
                  type="button"
                  onClick={handleEmailClick}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                >
                  Email
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}